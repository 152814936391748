import React, { useState, useEffect, useCallback } from 'react';
import { companyHelpers } from '@companydotcom/helpers';
import {
  Flex,
  Box,
  Text,
  Heading,
  Button,
  Radio,
  RadioGroup,
  InputGroup,
  Input,
  InputRightAddon,
  Center,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Link,
} from '@companydotcom/potion';
import { useTranslation, Trans } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _isEmpty from 'lodash/isEmpty';
import {
  useCreateDomainMutation,
  useLazyGetDomainQuery,
  useUpdateUserAccountDomainMutation,
} from '../../../services/user/user-api';
import type { DataCollectorFormProps } from '../../../features/data-collector';
// import {
// getDomain,
// createDomain
// } from '../../../services/user/old-api/user-svc';

const domainNameFormatter = (domain: string) => {
  return domain.replace(/_/g, ' ').trim().replace(/\s/g, '-');
};

export const DomainForm: React.FC<DataCollectorFormProps> = ({
  globalUser,
  productSlug,
  handleDataFormFill,
}) => {
  const [fqdnCompany, setFqdnCompany] = useState('');
  const [fqdnCustom, setFqdnCustom] = useState(globalUser?.account?.fqdnCustom || '');
  const [disableCompany, setDisableCompany] = useState(false);
  const [disableCustom, setDisableCustom] = useState(false);
  const [updateUserAccountDomain] = useUpdateUserAccountDomainMutation();
  const [createDomain] = useCreateDomainMutation();
  const [getDomain] = useLazyGetDomainQuery();
  const { t } = useTranslation();

  const defaultValues = {
    domainRadio: globalUser?.account?.fqdnCustom ? 'rdoFQDNCustom' : 'rdoFQDNCompany',
    fqdnCompany: '',
    fqdnCustom: '',
  };

  const getDomainFormSchema = () =>
    yup.object().shape({
      domainRadio: yup.string().required(),
      // company domain field validation
      fqdnCompany: yup.string().when('domainRadio', {
        is: (val: any) => val === 'rdoFQDNCompany',
        then: yup
          .string()
          .required(t('forms.domain.inputs.domainName.required'))
          .transform(val => domainNameFormatter(val))
          .test(
            'testOnlyLetNumHyp',
            t('forms.domain.inputs.domainName.validationCharacters'),
            val => {
              if (!val?.length || companyHelpers.validateHostname(val) === true) {
                return false;
              }
              return true;
            },
          )
          .test('testHypenPlacement', t('forms.domain.inputs.domainName.validationHyphen'), val => {
            if (val?.[0] === '-' || val?.[val?.length - 1] === '-') {
              return false;
            }
            return true;
          }),
      }),
      // custom domain field validation
      fqdnCustom: yup.string().when('domainRadio', {
        is: (val: any) => val === 'rdoFQDNCustom',
        then: yup
          .string()
          .required(t('forms.domain.inputs.domainName.required'))
          .transform(val => domainNameFormatter(val))
          .test('testValidFQDN', t('forms.domain.inputs.domainName.validation'), val => {
            // Validate that the FQDN is actually a valid Rackspace FQND
            if (
              !val?.length ||
              companyHelpers.validateFQDN(domainNameFormatter(val as string)) === false
            ) {
              return false;
            }
            return true;
          }),
      }),
    });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    setError,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getDomainFormSchema()),
    defaultValues,
  });
  const watchRadio = watch('domainRadio');

  const createSuggestion = useCallback(
    business => {
      // Create the domain name suggestion based on the business information
      let suggestion = business.name
        .replace(/_/g, ' ')
        .trim()
        .toLowerCase()
        .replace(/[^\w\s\][^,]/gi, '-')
        .replace(/\s/g, '-');

      // Check the Domain Table in dynamo to make sure the name is not already taken
      getDomain({ fqdn: `${suggestion}.${process.env.REACT_APP_CLIENT_DOMAIN_EMAIL}` })
        .unwrap()
        .then(verify1 => {
          // If the name is taken, append the city to the end of the name
          if (verify1 && verify1.fqdn) {
            suggestion += `${
              business.address.city && business.address.city !== undefined
                ? '-' + // eslint-disable-line
                  business.address.city
                    .replace(/_/g, ' ')
                    .trim()
                    .toLowerCase()
                    .replace(/[^\w\s\][^,]/gi, '-')
                    .replace(/\s/g, '-')
                : ''
            }`;
            // Check once more...
            getDomain({ fqdn: `${suggestion}.${process.env.REACT_APP_CLIENT_DOMAIN_EMAIL}` })
              .unwrap()
              .then(verify2 => {
                // If its taken again, append zipCode to the end
                if (verify2 && verify2.fqdn) {
                  suggestion += `${
                    business.address.city && business.address.city !== undefined
                      ? '-' + // eslint-disable-line
                        business.address.city
                          .replace(/_/g, ' ')
                          .trim()
                          .toLowerCase()
                          .replace(/[^\w\s\][^,]/gi, '-')
                          .replace(/\s/g, '-')
                      : ''
                  }`;
                  getDomain({ fqdn: `${suggestion}.${process.env.REACT_APP_CLIENT_DOMAIN_EMAIL}` })
                    .unwrap()
                    .then(verify3 => {
                      if (verify3 && verify3.fqdn) {
                        setValue('fqdnCompany', '', { shouldValidate: true });
                      } else {
                        setValue('fqdnCompany', suggestion, { shouldValidate: true });
                      }
                    });
                } else {
                  setValue('fqdnCompany', suggestion, { shouldValidate: true });
                }
              });
          } else {
            // If its not taken, set it
            setValue('fqdnCompany', suggestion, { shouldValidate: true });
          }
        });
    },
    [getDomain, setValue],
  );

  useEffect(() => {
    if (globalUser?.account?.fqdnCompany) {
      const fqdn = companyHelpers.validateCompanyDomain(globalUser?.account?.fqdnCompany);
      setValue('fqdnCompany', fqdn);
      setFqdnCompany(fqdn);
      setDisableCompany(true);
      setDisableCustom(true);
    }
    if (globalUser?.account?.fqdnCustom) {
      const fqdn = globalUser?.account?.fqdnCustom;
      setValue('fqdnCustom', fqdn);
      setFqdnCustom(fqdn);
      setDisableCustom(true);
      setDisableCompany(true);
    }
    if (!globalUser?.account?.fqdnCompany && !globalUser?.account?.fqdnCustom) {
      createSuggestion(globalUser?.account?.business?.[0]);
    }
  }, [
    setValue,
    createSuggestion,
    globalUser?.account?.business,
    globalUser?.account?.fqdnCompany,
    globalUser?.account?.fqdnCustom,
  ]);

  const onSubmit = async (values: typeof defaultValues) => {
    const companyFqdnCheck = await getDomain({
      fqdn:
        values.fqdnCompany && values.fqdnCompany.length
          ? `${values.fqdnCompany.toLowerCase()}.${process.env.REACT_APP_CLIENT_DOMAIN_EMAIL}`
          : ' ',
    }).unwrap();

    if (companyFqdnCheck && companyFqdnCheck.fqdn && !fqdnCompany.length) {
      setError('fqdnCompany', {
        type: 'manual',
        message: 'forms.domain.inputs.domainName.error',
      });
    }

    const customFqdnCheck = await getDomain({
      fqdn: values.fqdnCustom && values.fqdnCustom.length ? values.fqdnCustom.toLowerCase() : ' ',
    }).unwrap();

    if (customFqdnCheck && customFqdnCheck.fqdn && !fqdnCustom.length) {
      setError('fqdnCustom', {
        type: 'manual',
        message: 'forms.domain.inputs.domainName.error',
      });
    }

    let fqdn =
      values.fqdnCompany && values.fqdnCompany.length
        ? `${values.fqdnCompany}.${process.env.REACT_APP_CLIENT_DOMAIN_EMAIL}`
        : '';

    let emailCustom = false;
    let webCustom = false;

    if (watchRadio === 'rdoFQDNCustom') {
      if (productSlug === 'email_rackspace') {
        emailCustom = true;
      }

      if (productSlug === 'weebly') {
        webCustom = true;
      }
    }

    if (watchRadio === 'rdoFQDNCompany' && !values.fqdnCustom) {
      values.fqdnCustom = '';
    }
    if (watchRadio === 'rdoFQDNCustom' && !values.fqdnCompany) {
      fqdn = '';
    }

    let domainOptions;

    const getDomainResponse = await getDomain({
      fqdn: watchRadio === 'rdoFQDNCompany' ? fqdn : values.fqdnCustom,
    }).unwrap();
    if (!_isEmpty(getDomainResponse)) {
      domainOptions = { ...getDomainResponse };

      if (productSlug === 'weebly') {
        domainOptions.isRequiredAlias = 1;
      }
      if (productSlug === 'email_rackspace') {
        domainOptions.isRequiredMX = 1;
      }
    } else {
      domainOptions = {
        fqdn: watchRadio === 'rdoFQDNCompany' ? fqdn : values.fqdnCustom,
        domainName:
          watchRadio === 'rdoFQDNCompany'
            ? companyHelpers.validateCompanyDomain(values.fqdnCompany)
            : companyHelpers.validateCompanyDomain(values.fqdnCustom),
        zone: process.env.REACT_APP_CLIENT_DOMAIN_EMAIL,
        domainType: 'customer',
        address: `${process.env.REACT_APP_DOMAIN_ADDRESS}`,
        isRequiredAlias: productSlug === 'weebly' ? 1 : 0,
        isRequiredMX: productSlug === 'email_rackspace' ? 1 : 0,
      };
    }

    try {
      if (globalUser?.accountId) {
        const res = await updateUserAccountDomain({
          accountId: globalUser.accountId,
          fqdnCompany: fqdn,
          fqdnCustom: values.fqdnCustom,
          isEmailFQDNCustom: emailCustom,
          isWebFQDNCustom: webCustom,
        });

        if (res) {
          await createDomain({ options: domainOptions });
          handleDataFormFill();
        } else {
          throw new Error('could not create domain');
        }
      }
    } catch (err) {
      console.log('Error creating domain: ', err);
    }
  };

  return (
    <Center
      className="form-firstTimeForm__container"
      flexDirection="column"
      py={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box textAlign="center" maxWidth={580}>
        <Heading as="h1" size="hs-xl">
          {t('forms.domain.heading')}
        </Heading>
        <Text textStyle="md" mt={4}>
          {t('forms.domain.subheading')}
        </Text>
      </Box>
      <Box as="form" className="form-firstTimeForm__form" maxWidth={580} width="full" mt={[8, 10]}>
        <Controller
          name="domainRadio"
          control={control}
          render={({ field }) => (
            <>
              <RadioGroup {...field}>
                <Box p={6} border="1px solid" borderColor="gray.100">
                  <Flex flexDirection="column" textAlign="left">
                    <Radio
                      alignItems="flex-start"
                      value="rdoFQDNCompany"
                      onChange={() => {
                        setValue('fqdnCustom', '');
                      }}
                      isDisabled={disableCompany}
                    >
                      <Trans i18nKey="forms.domain.recommendedDomain">
                        <Text textStyle="md">
                          <strong>Use a branded Vastly subdomain</strong>
                          <br />
                          Use a free Vastly subdomain to get started with no setup. Based on the
                          information you provided, we recommend:
                        </Text>
                      </Trans>
                    </Radio>

                    <Flex flexDirection="column" ml={6} mt={4}>
                      <FormControl id="fqdnCompany" isInvalid={!!errors?.fqdnCompany}>
                        <FormLabel htmlFor="fqdnCompany">
                          {t('forms.domain.inputs.domainName.label')}
                        </FormLabel>
                        <InputGroup>
                          <Input
                            borderTopRightRadius={0}
                            borderBottomRightRadius={0}
                            isReadOnly={disableCompany}
                            {...register('fqdnCompany')}
                          />
                          <InputRightAddon
                            children={`.${process.env.REACT_APP_CLIENT_DOMAIN_EMAIL}`}
                          />
                        </InputGroup>
                        <FormErrorMessage color="red">
                          {errors?.fqdnCompany?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                </Box>

                <Box p={6} border="1px solid" borderColor="gray.100">
                  <Flex flexDirection="column" textAlign="left">
                    <Radio
                      alignItems="flex-start"
                      value="rdoFQDNCustom"
                      onChange={() => {
                        setValue('fqdnCustom', '');
                      }}
                      isDisabled={disableCustom}
                    >
                      <Trans i18nKey="forms.domain.preownedDomain">
                        <Text textStyle="md">
                          <strong>Use a domain you already own</strong>
                          <br />
                          Use a domain that you have already set up and we’ll help you connect it
                          when you are ready to publish.
                        </Text>
                      </Trans>
                    </Radio>

                    <Flex flexDirection="column" ml={6} mt={4}>
                      <FormControl id="fqdnCustom" isInvalid={!!errors?.fqdnCustom}>
                        <FormLabel htmlFor="fqdnCustom">
                          {t('forms.domain.inputs.domainName.label')}
                        </FormLabel>
                        <Input isReadOnly={disableCustom} {...register('fqdnCustom')} />
                        <FormErrorMessage>{errors?.fqdnCustom?.message}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                </Box>
              </RadioGroup>
            </>
          )}
        />

        <Box textAlign="center" mt={[8, 12]}>
          <Button
            className="form-domainForm__submitButton"
            data-test="form-domainForm__submitButton"
            type="submit"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {t('forms.domain.submitButton')}
          </Button>
        </Box>
        {productSlug === 'weebly' && (
          <Text textStyle="sm" textAlign="center" mt={6}>
            {t('forms.domain.weeblyTerms')}{' '}
            <Link href="https://www.weebly.com/terms-of-service" isExternal>
              {t('common.misc.termsOfUse')}
            </Link>
          </Text>
        )}
      </Box>
    </Center>
  );
};
